
/*HEADER*/

header{
    /*position: absolute;
    top: 0;*/
    width: 100%;
    /*max-width: 600px;*/
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.2em;
    padding-top: 22px;
    z-index: 99;
    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
}

.menu-desktop {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
   /* position: relative;*/
    margin: 0 60px;
    z-index: 10;
 
}

.menu-mobile{
    display: none;
}

header .active {
    display: flex !important;
  }

header .logo {
    position: absolute;
    left: 0;
    width: 200px;
}

header a{
    margin: 0 16px;
    cursor: pointer;
    padding: 8px 24px 8px 24px;
    border-radius: 4px;
}

header .menu-list a{
    color: #FFFFFF;
    background-color: rgba(0, 0, 0, 0.35);
}

header .menu-list-dark a{
    color: #000000;
    background-color:#F9E5AF;
}


header .menu-list-dark a:hover{
    color: #000000;
    background-color:#FFF0C9;
}

header .menu-list a:hover{
    color: #FCEBBC;
    background-color: rgba(0, 0, 0, 0.65);
}

/*CONNECT WALLET MODAL*/
.wallet-type-box{
    background-color: #F9E5AF;
    padding: 8px 24px;
    margin: 8px 0;
    width: 80%;
    border-radius: 5px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.wallet-type-box:hover{
    background-color:  #FFF0C9;
}

.wallet-type-box span{
  line-height: 18px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.2em;
}


/* HOME */


#bgKoi{
    position: absolute;
    right: 0;
    bottom: 30%;
    width: 850px;
    height: fit-content;
    object-fit: contain;
    z-index: -1;
    overflow: hidden;
}

#bgKoi img{
    position: relative;
    width: 1150px;
    opacity: 1;
}

#hero_mountains{
    position: relative;
    top: 0;
    object-fit: contain;
    z-index: 10;
}

#hero_container{
    position: absolute;
    object-fit: contain;
    object-position: top center;
    z-index: 11;
   /* background-color: green;*/
}

#section1_waterfalll{
    position: absolute;
    object-fit: contain;
    max-width: 33%;
    z-index: 10;
}

#home_mint_button{
    position: fixed;
    top: 0;
    right: 0;
    object-fit: contain;
    object-position: 100%;
    z-index: 99;
    width: auto;
}

#home_mint_button01{
    position: fixed;
    top: -15px;
    right: -15px;
    object-fit: contain;
    object-position: 100%;
    z-index: 99;
    width: auto; 
}

#home_mint_button02{
    position: fixed;
    top: -15px;
    right: -15px;
    object-fit: contain;
    object-position: 100%;
    z-index: 99;
    width: auto; 
   
}

#home_mint_button_text{
    font-size: 23px;
    position: fixed;
    top: 46px;
    right: 59px;
    z-index: 99;
    transform: matrix(0.23, -0.97, 0.97, 0.23, 0, 0);
    -webkit-transform: matrix(0.23, -0.97, 0.97, 0.23, 0, 0);
}
.home_mint_button_click_container{    
    cursor: pointer;
    position: fixed;
    top: 0;
    right: 0;
    width: 100px;
    height: 20vh;
    z-index: 99;
}

#section1_bg{
    width: 100%;
    position: absolute;
    object-fit: contain;
}

#scroll_up_button {
    width: 62px;
    height: 65px;
    color: #FCEBBC;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_bubble_small.png");
    background-repeat: no-repeat;
    background-size: contain;
}

#about_plant01 {
    width: 242.25px;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 11;
}

#about_plant02 {
    width: 521.9px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 11;
}

.Home{
    background-color: #F9EDC5;
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
       -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
}

.hero{
    width: 100%;
    padding-top: 110px;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/hero_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}


.section1{
    position: relative;
    width: 100%;
    /*min-height: 250vh;*/
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section1_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 100%;
    z-index: 10;
    padding-bottom: 230px;
}

.section1-container{
    z-index: 10;
    position: relative;
    top: 29vh;
    width: 100%;
    max-width: 1850px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section1-container-wrapper{
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.section1-box-one{
    /*width: 386px;*/
    width: 55%;
    max-width: 360px;
    filter: drop-shadow(0px 37px 85px rgba(52, 12, 12, 0.25));
    margin-right: 100px;
}

.section1-box-one img{
    border-radius: 8px;
}

.section1-box-two{
    width: 45%;
}

.section1-box-two-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
}

.section1-box-two h2{
    margin-bottom: 24px;
}

.section1-box-two p{
    margin-bottom: 40px;
}

.carousel-arrow-left{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
}

.carousel-arrow-right{
    width: 50px;
    height: 50px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    z-index: 11;
    cursor: pointer;
}

.carousel-arrow-container{
    position: relative;
    width: 100%;
}

.carousel-arrow-left .carousel-arrow-container div{
    color: #FCEBBC;
    width: 55px;
    height: 55px;
    position: absolute;
    left: -50%;
    cursor: pointer;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_bubble_small.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}

.carousel-arrow-right .carousel-arrow-container div{
    color: #FCEBBC;
    width: 55px;
    height: 55px;
    position: absolute;
    right: -50%;
    cursor: pointer;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_bubble_small.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
}
.carousel{   
    z-index: 2;
    width: 100%;
    padding: 6px 0;
    display: flex;
    overflow-x: hidden;
    overflow-y: hidden;   
   /* scroll-behavior: smooth;
    transition: all 5s ease-in;*/
    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
}

.carousel .carousel-container{
    display: flex;
    margin: 4px 0;
}

.carousel .koi-card{
    width: 157.5px;
    height: 280px;
    /*background-color: #000000;*/
    margin: 0 8px;
    filter: drop-shadow(0px 15px 29px rgba(52, 12, 12, 0.18));
    border-radius: 8px;
    cursor: pointer;
}

.koi-card img{
    border-radius: 8px;
}

.carousel .koi-card:hover {
    box-sizing: border-box;
    border: 1px solid #8BF1F8;
    filter: drop-shadow(0px 0px 12px #8BF1F8) drop-shadow(0px 15px 29px rgba(52, 12, 12, 0.18));
}


.section1-plant-detail {
    align-self: flex-end;
    width: 361px;
    height: 212px;
    position: relative;
    right: 0;
    z-index: 1;
}

.section1-plant-detail img:nth-of-type(1){
    position: absolute;
    right: 30px;
    width: 70px;
    object-fit: contain;
    z-index: 5;
    
}

.section1-plant-detail img:nth-of-type(2){
    position: absolute;
    top: 60%;
    transform: translateY(-50%);
    object-fit: contain;
    z-index: 4;
}

.section1-plant-detail img:nth-of-type(3){
    position: absolute;
    left: 14%;
    top: 74%;
    object-fit: contain;
    width: 47px;
    z-index: 5;
}

.section2 {  
    position: relative;
    top: -40vh;
    width: 100%;
    min-height: 130vh;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section2_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
   /* mix-blend-mode: multiply;*/
    /*padding-bottom: 230px;*/
}

.section2-container{
    z-index: 10;
    position: relative;
    top: 44vh;
    width: 80%;
    max-width: 1290px;
    /*background-color: rgb(0, 0, 0, 0.3);*/
}

.section2-container-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.section2-box-one{
    width: 55%;
    color: #FCEBBC;
   /* width: 326px;*/
   /* filter: drop-shadow(0px 37px 85px rgba(52, 12, 12, 0.25));*/
    margin-right: 100px;
}

.section2-box-one p:nth-of-type(1){
    color: #ffffff;
    letter-spacing: 0.15em;
    font-weight: 400;
    margin-bottom: 8px;
}

.section2-box-one h2{
    margin-bottom: 24px;
}


.section2-box-one p:nth-of-type(2), .section2-box-one p:nth-of-type(3){
    margin-bottom: 24px;
    color: #FFF7E1;
}

.section2-box-two{
    position: relative;
    width: 45%;
    height: 600px;
    margin-bottom: 40px;
}

.section2-box-two img{
    position: absolute;
}

.section2-box-two img:nth-of-type(1){
    top: 10%;
    left: 20%;
    max-width: 38px;
}


.section2-box-two img:nth-of-type(3){
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 360px;
}

.section2-box-two img:nth-of-type(4){
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 265px;
}

.section2-box-two img:nth-of-type(2){
    top: 69%;
    left: 66%;
    max-width: 107px;
}

.section2-box-two img:nth-of-type(6){
    max-width: 124px;
    bottom: 0;
    left: 7%;
}

.section2-box-two img:nth-of-type(5){
    max-width: 52px;
    bottom: 0;
    left: 13%;
}

#section2_kanji{
    position: absolute;
    top: -6.5%;
    right: -5%;
    width: 120px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.5));
}



.section3 {
    z-index: 9;
    position: relative;
    top: -105vh;
    width: 100%;
    min-height: 105vh;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section3_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    /*padding-bottom: 230px;*/
}

.section3-container{
    z-index: 10;
    position: relative;
    top: 45vh;
    width: 93%;
    max-width: 1650px;
    height: fit-content;
}

.section3-container-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
}

.section3-box-one{
    position: relative;
    width: 48%;
    height: 610px;
   /* background-color: rgba(0, 0, 0, 0.2);*/
    margin-right: 100px;
}

.section3-box-one img{
    position: absolute;
}

.section3-box-one img:nth-of-type(1){
    max-width: 100px;
    top: -6%;
    left: 30%;
    /*transform: translateX(-50%);*/
    transform: rotate(-4.11deg);
}

.section3-box-one img:nth-of-type(2){
    max-width: 268px;
    bottom: 0;
    left: 7%;
    transform: rotate(-1.5deg);
    z-index: 11;
}

.section3-box-one img:nth-of-type(3){
    max-width: 234px;
    top: -13%;
    left: 3%;
    transform: matrix(-0.98, 0.21, 0.21, 0.98, 0, 0);
}

.section3-box-one img:nth-of-type(4){
    max-width: 481px;
    bottom: 6%;
    right: 0;
    transform: rotate(-6.14deg);
}

.section3-box-two{
    position: relative;
    top: -50px;
    width: 52%;
    color: #000000;
}

.section3-box-two h2{
    margin-bottom: 24px;
}

.section4 {
    background-color: #F9EDC5;
    position: relative;
    top: -130vh;
    width: 100%;
    min-height: 80vh;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section4_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    display: flex;
    justify-content: center;
    mix-blend-mode: multiply;
    padding-bottom: 10vh;
   /* overflow-x: hidden;*/
}

.section4-container{
    z-index: 10;
    position: relative;
    top: 37vh;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
}

.section4-container-wrapper{
    display: flex;
    align-items: center;
   justify-content: flex-start;
    width: 100%;
    overflow: hidden;
}

.section4-box-one{
    padding-left: 10%;
    width: 40%;
    color: #000000;
    filter: drop-shadow(0px 37px 85px rgba(52, 12, 12, 0.25));
}

.section4-box-one h2{
    margin-bottom: 24px;
}

.section4-box-one p{
    font-weight: 500;
}

.section4-box-two{
    width: 60%;
    min-width: 700px;
    overflow: hidden;
}

.partnership-list{
    position: relative;
    /*margin-left: 40px;*/
    display: flex;
    width: 200%;
    overflow-x: hidden;
    height: 216px;
}

.partnership-list .partnership-list-container {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 200%;
}

@keyframes scroll {
    0% {
      left: 0;
    }
    100% {
      left: calc(-100%);
    }
  }
  
.animate {
animation: scroll 20s linear infinite;
}


.partnership-card{
    background-color: #12101F;
    width: 216px;
    height: 216px;
    border-radius: 16px;
    margin: 0 8px;
    cursor: pointer;
}

.partnership-card:hover{
    opacity: .84;

}



.partnership-card:nth-of-type(1), .partnership-card:nth-of-type(8){
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/partnership01.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.partnership-card:nth-of-type(2), .partnership-card:nth-of-type(9){
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/partnership02.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}


.partnership-card:nth-of-type(3), .partnership-card:nth-of-type(10){
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/partnership03.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;

}

.partnership-card:nth-of-type(4), .partnership-card:nth-of-type(11){
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/partnership04.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.partnership-card:nth-of-type(5), .partnership-card:nth-of-type(12){
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/partnership05.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.partnership-card:nth-of-type(6), .partnership-card:nth-of-type(13){
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/partnership06.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}

.partnership-card:nth-of-type(7), .partnership-card:nth-of-type(14){
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/partnership07.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}





/*FOOTER*/

footer{
    padding: 24px 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 1100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: "Inter";
    color: #fff;
    z-index: 20;
}

.footer-icons {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 24px 0;
}

.footer-icons .single-icon-box{
    background: #0d1221;
    color: #fcebbc;
    width: 42.22px;
    height: 42.22px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 0 6.5px;
}

.footer-icons .single-icon-box img{
    width: 25px;
    height: 25px;
}


.footer-icons .single-icon-box:hover{
    background-color: rgba(13, 18, 33, 0.94);
    color: rgba(252,235,188,0.94);
}

.footer-info {
    display: flex;
    position: relative;
    width: 100%;
}


.terms-and-conditions a{
    cursor: pointer;
     color: #fff;
}

.terms-and-conditions a:nth-of-type(1){
   margin-right: 39px;
}


.align-item-center{
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.align-item-end{
    position: absolute;
    right: 0;
}




@media (min-height: 890px) {
    .hero{
        padding-top: 120px;
    }
    .section1{
        padding-bottom: 300px;
    }
    
}


@media (min-height: 1000px) {
    .hero{
        padding-top: 175px;
    }
    .section1{
        padding-bottom: 400px;
    }
}



@media (min-height: 1400px) {
    .hero{
        padding-top: 175px;
    }
    .section1{
        padding-bottom: 400px;
    }
}

@media (max-height: 1000px) {

    @media (min-width: 1900px){
        #section1_waterfalll{
            max-width: 35%;
        }
    }
    
    .section2 {
        min-height: 140vh;
    }
    .section3 {
        top: -105vh;
        min-height: 120vh;
    }
    .section3-container{
        top: 47vh;
    }

    .section3-box-one{
        height: 610px;
    }

    .section4 {
        min-height: 90vh;
    }
    
}

@media (max-height: 800px) {
    .section2 {
        min-height: 150vh;
    }
    .section3 {
        min-height: 145vh;
    }
    .section3-container{
        top: 56vh;
    }
    .section4 {
        min-height: 100vh;
    }
}

@media (max-width: 2050px) {
    #section1_waterfalll{
        max-width: 40%;
    }

    .section1-container-wrapper{
        width: 100%;
    }

    .section1-box-two {
        margin-right: 108px;
    }

    .section3-container{
      /*  top: 45vh;*/
    }
}



@media (max-width: 1750px) {
    #section1_waterfalll{
        max-width: 40%;
        left: -2%;
    }
}

@media (max-width: 1600px) {
    #section1_waterfalll{
        max-width: 50%;
        left: -4%;
    }

    @media (max-height: 1000px) {
        #section1_waterfalll{
            max-width: 45%;
            left: -4%;
        }
    }
}

@media (max-width: 1440px) {
    #about_plant01 {
        width: 205px;
    }
    
    #about_plant02 {
        width: 442px;
    }

    .section1{
        background-image: url("images/home_section1_background_1440.png")
    }
    

    .section1-container-wrapper{
        width: 90%;
    }
    .section1-box-one{
        max-width: 292px;
        margin-right: 80px;
    }
    .section1-plant-detail{
        right: 10%;
    }

    .section2{
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section2_background_1440.png");
    }

    .section2-container{
        z-index: 10;
        position: relative;
        top: 44vh;
        width: 90%;
        max-width: 1290px;
    }

    .section2-box-one{
        margin-right: 80px;
    }

    .section2-box-two{
       display: flex;
       justify-content: flex-start;
    }

    .section2-box-two-wrapper{
        width: 90%;
        position: relative;
    }

    #section2_kanji{
        position: absolute;
        top: -6.5%;
        right: 0;
    }

    .section3{
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section3_background_1440.png");
    }

    .section3-container{
        width: 96%;
    }

    .section3-box-one{
        width: 50%;
        margin-right: 10px;
    }

    .section3-box-one img:nth-of-type(4){
        bottom: 10%;
        max-width: 445px;
    }

    .section3-box-two{
        width: 50%;
        padding-right: 40px;
    }

    .section4{
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section4_background_1440.png");
        background-position: bottom;
    }

    .section4-box-one{
        padding-left: 5%;
        min-width: 285px;
        color: #000000;
        margin-right: 80px;
    }

    /*.section4-box-two{
        width: 55%;
    }*/
    
    
    
}


@media (max-width: 1100px) {
    header .logo {
        position: absolute;
        left: 0;
        width: 170px;
    }
    header .menu-desktop {
        justify-content: flex-end;
        margin: 0 90px;
    }

    footer{
        max-width: 900px;
    }
    

    .section1-container{
        align-items: flex-end;
    }

    .section3-box-one img:nth-of-type(1){
        max-width: 85px;
        top: -6%;
        left: 35%;
    }
    
    .section3-box-one img:nth-of-type(2){
        max-width: 227.8px;
        bottom: 9%;
        left: 9%;
    }
    
    .section3-box-one img:nth-of-type(3){
        max-width: 198.9px;
        left: 1%;
    }

    .section3-box-one img:nth-of-type(4){
        bottom: 22%;
        max-width: 390px;
    }

    .section3-box-two{
        width: 50%;
        padding-right: 15px;
    }
}

@media (max-width: 900px) {

    header .logo {
        position: absolute;
        left: 0;
        width: 40px;
    }
    header a{
        margin: 0 12px;
        cursor: pointer;
        padding: 5px 20px 5px 20px;
        border-radius: 4px;
    }
    header .menu-desktop {
        justify-content: center;
        margin: 0;
    }

    .footer-icons {
        padding: 12px 0;
    }

    .footer-info {
      flex-direction: column;
      align-items: center;
    }

    .footer-info div, .footer-info span{
        margin: 2px 0;
      }

    .align-item-center{
        position: static;
        left: auto;
        transform: none;
    }
    
    .align-item-end{
        position: static;
    }

    /*HOME*/

    .Home{
        background-color: #F9EDC5;
        width: 100%;
        height: 270vh;
        position: absolute;
        top: 0;
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/hero_background.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: top;
        
    }
    

    #about_plant01 {
        width: 184px;
    }
    
    #about_plant02 {
        width: 375px;
    }

    .hero{
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/hero_background.png");
        height: 100vh;
        padding-top: 0;
        position: relative;
    }

    #hero_mountains{
       /* position: absolute;
        bottom: 0;
        object-fit: cover;
        height: 85vh;*/
        display: none;
    }
    
    #hero_container{
        z-index: 20;
        position: absolute;
        bottom: 0;
        object-fit: cover;
        height: 90vh;
    }

   /* #home_mint_button{
        z-index: 90;
        height: 80vh;
    }

    .home_mint_button_click_container{
        top: 0;
        width: 80px;
        height: 15vh;
        z-index: 91;
    }*/
    

    .section1{
        position: relative;
        width: 100%;
        height: fit-content;
        padding: 0;
        margin: 0;
        z-index: 10;
    }

    .section1-container{
        padding-bottom: 0;
        z-index: 10;
        position: relative;
        top: 0;
        height: fit-content;
        width: 100%;
        max-width: 1850px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .section1-container-wrapper{
        width: 100%;
        justify-content: center;
        margin: 76px 0 136px 0;

    }
    
    #section1_waterfalll{
        display: none;
    }

    .section1-box-one{
        width: 85%;
        margin: 0;
        display: none;
    }

    .section1-box-two{
        width: 85%;
        margin-right: 0;
        display: flex;
        justify-content: center;
    }
    .section1-box-two p{
        margin-bottom: 20px;
    }

    .carousel-arrow-left .carousel-arrow-container{
        display: none;
    }
    
    .carousel-arrow-right .carousel-arrow-container{
        display: none;
    }

    .carousel{
        overflow: scroll;
    }
    

    .section1-plant-detail{
        display: none;
    }

    
    .section2 {
        position: relative;
        top: -90px;
        width: 100%;
        min-height: 0;
        height: fit-content;
        display: flex;
        justify-content: center;
        
    }

    .section2-container{
        position: relative;
        top: 0;
        width: 85%;
    }
    

    .section2-container-wrapper{
        flex-direction: column-reverse;
        margin: 166px 0 136px 0;
    }

    .section2-box-one{
        width: 100%;
        margin-right: 0;
       text-align: center;
    }

    .section2-box-one h2{
        font-size: 36px;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 0em;
    }

    .section2-box-one p:nth-of-type(2), .section2-box-one p:nth-of-type(3){
        text-align: left;
    }

    .section2-box-two{
        position: relative;
        width: 100%;
        height: 300px;
        justify-content: center;
        margin-bottom: 65px;
    }

    .section2-box-two-wrapper{
        width: 100%;
    }

    .section2-box-two-wrapper img:nth-of-type(1){
        top: 4%;
        left: 27%;
        z-index: 4;
    }

    .section2-box-two-wrapper img:nth-of-type(2){
        top: 75%;
    }


    .section2-box-two-wrapper img:nth-of-type(3){
        max-width: 359px;
    }

    .section2-box-two-wrapper img:nth-of-type(4){
        max-width: 276px;
    }

    .section2-box-two-wrapper img:nth-of-type(2){
        max-width: 107px;
    }

    /*.section2-box-two-wrapper img:nth-of-type(6){
      
    }*/

    #section2_kanji{
        /*display: none;*/
        top: calc(-3.5% + 90px);
        right: 5%;
        max-width: 91px;
    }

    .section3 {
        top: -125px;
        min-height: 0;
        width: 100%;
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section3_background_768.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: bottom;
        height: fit-content;
    }

    .section3-container{
        z-index: 10;
        position: relative;
        top: 0;
        width: 100%;
        max-width: 1650px;
        height: fit-content;
    }
    
    
    
    .section3-container-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0 166px 0;
    }

    .section3-box-one{
        width: 85%;
        max-width: 500px;
        height: 410px;
        margin-right: 0;
        margin-bottom: 26px;
    }

   .section3-box-one img:nth-of-type(1){
        max-width: 85px;
        top: 0%;
        left: 35%;
        z-index: 10;
    }
    
    .section3-box-one img:nth-of-type(2){
        max-width: 227.8px;
        bottom: 9%;
        left: auto;
        right: 0;
    }
    
    .section3-box-one img:nth-of-type(3){
        max-width: 198.9px;
        left: 0;
        top: -6%;
    }

    .section3-box-one img:nth-of-type(4){
        bottom: 0;
        max-width: 390px;
    }

    .section3-box-two{
        position: relative;
        top: 0;
        width: 85%;
        text-align: center;
        padding-right: 0;
    }

    .section3-box-two h2{
        font-size: 36px;
        line-height: 40px;
    }

    .section4 {
        background-color: #F9EDC5;
        position: relative;
        top: -320px;
        width: 100%;
        min-height: 0;
        height: fit-content;
        display: flex;
        justify-content: center;
        /*padding-bottom: 230px;*/
    }

    .section4-container{
        top: 0vh;
    }

    .section4-container-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
       justify-content: flex-start;
        width: 100%;
        max-width: 1290px;
        margin: 320px 0 246px 0;
    }

    .section4-box-one{
        width: 85%;
        min-width: 0;
        margin-right: 0;
        text-align: center;
        margin-bottom: 32px;
    }

    .section4-box-one h2{
        margin-bottom: 16px;
    }
   
}


@media (max-width: 768px) {

    .section1 {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section1_background_768.png");
        background-position: bottom;
    }
       
    .section2 {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section2_background_768.png");
    }

    /*.section3 {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section3_background_768.png");
    }*/

    .section4 {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section4_background_768.png");
        background-position: bottom;
    }

}





@media (max-width: 500px) {

    footer{
        padding: 24px 0;
        position: absolute;
    }
    
    header{
        padding-top: 32px;
    }
   
    header .menu-desktop {
        display: none;
    }

    header .menu-mobile{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 24px;
        z-index: 99;
    }

    header .menu-mobile-icon {
        cursor: pointer;
    }

    header .menu-mobile-list {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        z-index: 99;
        background-color: #e2c47f;
        width: 100%;
        display: none;
        box-shadow: 0px -5px 27px rgba(0, 0, 0, 0.3);
        border-radius: 24px 24px 0px 0px;
        padding-bottom: 20px;
      }   
      
    header .menu-mobile-list a{
        color: #000;
        margin: 8px 0;
        background-color: #F9E5AF;
        padding: 8px 24px;
        width: 70%;
        max-width: 288px;
        border-radius: 5px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        font-weight: 600;
        letter-spacing: 0.2em;
        line-height: 18.2px;
        font-size: 14px;
    } 

    header .menu-mobile-list a:hover{
        background-color:#FFF0C9;
    }

    header .logo {
        position: static;
        width: 40px;
    }

    header .logo a{
        margin: 0;
        padding: 0;
    }


    /*HOME*/

    #about_plant01 {
        /*width: 184px;*/
        display: none;
    }
    
    #about_plant02 {
       /* width: 375px;*/
       display: none;
    }

    .hero{
        height: 100vh;
        padding-top: 0;
        position: relative;
    }

    #hero_mountains{
        position: relative;
        object-fit: contain;
        object-position: center;
        display: none;
    }
    
    #hero_container{
        z-index: 20;
        position: absolute;
        top: 110px;
        object-fit: cover;
        object-position: center;
        height: 85vh;
       /* background-color: green;*/
    }

    #home_mint_button{
        position: absolute;
        top: 100vh;
        transform: translateY(-100%);
        object-fit: contain;
        z-index: 23;
        width: 100%;
        /*height: fit-content;*/
    }

    #home_mint_button01{
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        right: 0;
        object-fit: contain;
        z-index: 25;
        width: 100%;
        height: fit-content;
    }
    
    #home_mint_button02{
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        right: 0;
        bottom: auto;
        object-fit: contain;
        z-index: 25;
        width: 100%;
        height: fit-content;
    }

    .mint-button-text-container{
        position: absolute;
        top: 0;
        transform: translateY(-100%);
        right: 0;
        width: 100%;
        height: fit-content;  
        overflow: hidden; 
        z-index: 26;
     }

    #home_mint_button_text{
        font-size: 25px;
        position: relative;
        width: 100%;
        height: fit-content;
        top: auto;
        right: 0;
   
       transform: matrix(0.97, 0.23, -0.23, 0.97, 0, 0);
        -webkit-transform: matrix(0.97, 0.23, -0.23, 0.97, 0, 0);
    }
    

    .home_mint_button_click_container{
        position: absolute;
        top: 100vh;
        transform: translateY(-100%);
        height: fit-content;
        width: 100%;
        z-index: 30;
    }

    .section1{
        position: relative;
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section1_background_360.png");
        width: 100%;
        height: fit-content;
        padding: 0;
        margin: 0;
        z-index: 10;
    }

    .section1-container{
        padding-bottom: 0;
        z-index: 10;
        position: relative;
        top: 0;
        height: fit-content;
        width: 100%;
        max-width: 1850px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .section1-container-wrapper{
        width: 100%;
        justify-content: center;
        margin: 96px 0;

    }
    
    #section1_waterfalll{
        display: none;
    }


    .section1-box-two h2{
        margin-bottom: 16px;
        max-width: 90%;
        font-size: 32px;
        line-height: 36px;
        letter-spacing: 0em;

    }
    
    .section1-box-two p{
        margin-bottom: 32px;
        max-width: 100%;
    }

    .section1-plant-detail{
        display: none;
    }

    
    .section2 {
        position: relative;
        top: -40px;
        width: 100%;
        min-height: 0;
        height: fit-content;
        display: flex;
        justify-content: center;
    }

    .section2-container{
        position: relative;
        top: 0;
        width: 85%;
    }
    

    .section2-container-wrapper{
        flex-direction: column-reverse;
        margin: 96px 0;
    }

    .section2-box-one{
        width: 100%;
        margin-right: 0;
       text-align: left;
    }

    .section2-box-one h2{
        font-size: 36px;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: 0em;
    }

    .section2-box-one p:nth-of-type(2), .section2-box-one p:nth-of-type(3){
        text-align: left;
    }

    .section2-box-two img:nth-of-type(5){
        display: none;
    }

    .section2-box-two{
        position: relative;
        width: 100%;
        height: 300px;
        justify-content: center;
        margin-bottom: 15px;
    }

    .section2-box-two-wrapper{
        width: 100%;
    }

    .section2-box-two-wrapper img:nth-of-type(1){
        top: 0;
        left: 17%;
    }

    .section2-box-two-wrapper img:nth-of-type(2){
        top: 75%;
    }


    .section2-box-two-wrapper img:nth-of-type(3){
        max-width: 252px;
    }

    .section2-box-two-wrapper img:nth-of-type(4){
        max-width: 203px;
    }

    .section2-box-two-wrapper img:nth-of-type(2){
        max-width: 72px;
    }

    .section2-box-two-wrapper img:nth-of-type(6){
        display: none;
    }

    #section2_kanji{
        display: none;
    }

    .section3 {
        top: -80px;
        min-height: 0;
    }

    .section3-container{
        z-index: 10;
        position: relative;
        top: 30px;
        width: 100%;
        max-width: 1650px;
        height: fit-content;
    }
    
    
    
    /*.section3-container-wrapper{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 96px 0;
    }
*/
    .section3-box-one{
        width: 85%;
        max-width: 500px;
        height: 310px;
        margin-right: 0;
        margin-bottom: 0px;
    }
    .section3-box-one img:nth-of-type(1){
        max-width: 55px;
        top: -10%;
        left: 25%;
        z-index: 10;
    }
    
    .section3-box-one img:nth-of-type(2){
        max-width: 146px;
        bottom: 9%;
        left: auto;
        right: 5%;
    }
    
    .section3-box-one img:nth-of-type(3){
        max-width: 128px;
        left: 0;
        top: -6%;
    }

    .section3-box-one img:nth-of-type(4){
        bottom: 20%;
        max-width: 263px;
    }
    
    .section3-box-two{
        position: relative;
        top: 0;
        width: 85%;
        text-align: left;
        padding-right: 0;
    }

    .section3-box-two h2{
        font-size: 32px;
        line-height: 32px;
    }

    .section4 {
        min-height: 110vh;
    }
    

    .section4-container{
        top: -60px;
    }

    .section4-box-one{
        width: 85%;
        min-width: 0;
        margin-right: 0;
        text-align: left;
        margin-bottom: 32px;
    }

    .section4-box-one h2{
        margin-bottom: 16px;
    }
}

@media (max-width: 360px) {

    #home_mint_button_text{
        font-size: 20px;
    }
       
    .section2 {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section2_background_360.png");
    }

    .section3 {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section3_background_360.png");
    }

    .section4 {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section4_background_360.png");
    }

}

