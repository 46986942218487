html, body{
  margin: 0;
  padding: 0;
  font-family: 'KoHo', sans-serif;
  color: #000;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-repeat:no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  background-color: #F9EDC5;
}

code {
  font-family: 'KoHo', sans-serif;
}

#root{
  height: 100%;
  margin: 0;
  padding: 0;
}

/*CUSTOM SCROLL-BAR*/

/* width */
::-webkit-scrollbar {
  width: 8px;
  background-color: black;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(249, 237, 197); 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(249, 237, 197, .84); 
}

img{
  width: 100%;
  /*height: 100%; */
  object-fit: contain;
}

button{
  cursor: pointer;
  font-family: 'KoHo', sans-serif;
}

a{
  text-decoration: none;
  font-family: 'KoHo', sans-serif;
}
span { 
  font-family: 'KoHo', sans-serif;
}

p, li{
  width: 100%;
  font-family: 'KoHo', sans-serif;
  font-size: 16px;
  margin: 0;
  line-height: 24px;
}

button{
  background-color: rgba(0, 0, 0, 0);
  border: 0;
}

h2{
  font-size: 42px;
  line-height: 42px;
  font-weight: 600;
  margin: 0;
}


h3 {
  font-size: 36px;
  line-height: 39.6px;
  font-weight: 600;
  margin: 0;
}

h4{ 
  font-size: 30px;
  line-height: 36px;
  font-weight: 600;
  margin: 0;
}

h5{
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
  margin: 0;
}

.flex-row{
  display: flex;
}

.flex-row-center{
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-column{
  display: flex;
  flex-direction: column;
}

.fw400{
  font-weight: 400 !important;
}

.text-align-center{
  text-align: center;
}

.underline {
  text-decoration: underline;
}

.white{
  color: #FCEBBC;
}

.maxw90{
  max-width: 90%;
}

.maxw70{
  max-width: 70%;
}

.mb15{
  margin-bottom: 15px;
}

/* CUSTOM CSS CLASSES*/


.alice-carousel__next-btn, .alice-carousel__prev-btn {
  margin-top: 30px;
  color: rgba(249, 237, 197, .84) !important;
}

.alice-carousel__next-btn-item, .alice-carousel__prev-btn-item {
  font-size: 59px;
  color: #F9EDC5 !important;
}

/*********************/

@media (max-width: 540px) {


  h3 {
    font-size: 23px;
    line-height: 24px;
  }

  h4 {
    font-size: 20px;
    line-height: 21px;
  }

  h5 {
    font-size: 18px;
    line-height: 18px;
  }

  p, li{
    font-size: 15px;
    line-height: 24px;
  }
  

}

