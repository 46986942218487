.Mint {
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/mint_background.png");
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    position: absolute;
    top: 0;
    min-height: 100vh;
    width: 100%;
    color: #FCEBBC;
    display: flex;
    justify-content: center;
    z-index: 2;
}

#bgImage{
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: center;
}

#bgVideo{
    position: fixed;
    height: 100vh;
    width: 100%;
    z-index: -1;
    object-fit: cover;
    object-position: center;
}

.Mint-container{
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 140px;
}

.mint-section {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.box-one {
    /*height: 471px;*/
    width: 300px;
    margin-right: 10%;
    margin-left: 19%;
}

.box-two {
    max-width: 590px;
    height: fit-content;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/text_container_mint.png");
    background-repeat:no-repeat;
    background-position: center;
    background-size: contain;
    padding: 55px 55px 55px 55px;
    position: relative;
}

.box-two .box-two-info-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    top: 40px;
}

/*
.box-two-title-box{
    padding: 0 70px;
}*/

.box-two-title-box p{
    margin-bottom: 16px;
    font-weight: 600;
    letter-spacing: 0.15em;
}

.mint-info {
    display: flex;
    width: 100%
}

.box-two-contract-info:nth-child(1){
    border-right: 1px solid #FCEBBC;
    width: calc(50% + 1px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
}

.box-two-contract-info:nth-child(2){
    width: calc(50% - 1px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
}

.box-two-contract-info img{
    width: 12px;
    height: 20px;
    margin-right: 3px;
}

.box-two-contract-info div{
    font-weight: 600;
    font-size: 23px;
}


.box-two-contract-info p:nth-child(1),
.box-two-contract-info div p{
    font-weight: 600;
    font-size: 23px;
}

.box-two-margin-bottom {
    margin-bottom: 24px;
}

.box-two-margin-bottom-p {
    margin-bottom: 18px;
}


.box-two-contract-info{
   padding: 0 30px;
}

.mint-button-container{
    position: relative;
    bottom: -45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.mint-button-container button{
    position: relative;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 23px;
}

.mint-button-container button:hover{
    color: rgba(0, 0, 0, .5);
    font-size: 24px;
}

.mint-button-container a.mint-button-text{
    position: absolute;
    top: 43%;
    left: calc(50%);
    transform: translate(-50%, -50%);
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.1em;
    color: #000;
}

.mint-button-container img{
    position: relative;
    left: -10.5px;
    width: 260px;
}

@keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.bubble-container {
    width: 100%;
    height: 435px;
    margin: 0 auto;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/bubble.svg");
    background-repeat:no-repeat;
    background-position: center;
    background-size: contain;

    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
}


.bubble-container-error {
    width: 100%;
    height: 455px;
    position: relative;

    -moz-user-select:none; /* firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE*/
    user-select: none; /* Standard syntax */
}

.animate-container{
    animation: rotate 40s linear infinite;
}

.container-bg-image {
    position: absolute;
    z-index: -1;
    height: 100%;
    width: 100%;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/error_bubble.png");
    background-repeat:no-repeat;
    background-position: center;
    background-size: contain;
}

.bubble-container-wrapper-error, .bubble-container-wrapper-success{
    text-align: center;
    padding: 0 81px 0 81px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 455px;
}

.bubble-container .bubble-container-wrapper{
    text-align: center;
    padding: 90px 83px 0 81px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bubble-container-wrapper h3{
    margin-bottom: 19px;
}

.bubble-container .bubble-container-wrapper div{
    display: flex;
    align-items: center;
    margin-bottom: 23px;
}

.bubble-container .bubble-container-wrapper div p{
    font-size: 23px;
    font-weight: 600;
}

.bubble-container .bubble-container-wrapper div e{
    font-weight: 300;
}

.bubble-container .bubble-container-wrapper div img{
    width: 12px;
    height: 20px;
    margin-right: 3px;
}

.bubble-container .bubble-container-wrapper .amount-box{
    background: #0d1221;
    color: #FCEBBC;
    width: 72px;
    height: 72px;
    border-radius: 8px;
    cursor: pointer;
    margin: 5px 23px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bubble-container .bubble-container-wrapper .amount-box h3{
    margin-bottom: 0;
}

.bubble-two-container{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px 0;
    width: 59px;
    height: 62px;
    align-self: center;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/bubble02.svg");
    background-repeat:no-repeat;
    background-position: center;
    background-size: contain;
    cursor: pointer;
}

.bubble-two-container svg:hover{
    height: 50px;
    width: 50px;
}

.bubble-two-container svg:active{
    background-color: rgb(0, 0, 0, .2);
    border-radius: 50px;
}


/* AURA CLAIM PAGE */

.claim-page{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.not-live-container{
    background-color: rgba(0, 0, 0, 0.45);
    padding: 18px 44px 18px 44px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.nfts-slide{
    width: 90%;
}

.single-nft-container{
    background-color: rgba(0, 0, 0, 0.45);
    padding: 10px;
    min-height: 400px;
    margin: 0 3px;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: end;
}

.single-nft-image{
    margin-bottom: 10px;
}

.single-nft-info{
    margin-bottom: 10px;
}

.single-nft-info p{
    font-size: 18px;
}

.single-nft-buttons{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.claim-button{
    background-color: #F9E5AF;
    width: 98%;
    padding: 10px 0;
    border-radius: 4px;
    margin: 2.5px 0;
}

.claim-button:hover{
    background-color: rgb(255, 240, 201);
}


@media (max-width: 1445px) {
    .Mint {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/mint_background_1440.png");
    }

    .mint-section {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .box-one {
        /*height: 471px;*/
        width: 270px;
        margin-right: 9%;
        margin-left: 7%;
    }    

    .box-two {
        max-width: 410px;
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/text_container_mint_1440.png");
        padding: 55px 55px 55px 55px;
    }

    .box-two .box-two-info-container {
        top: 15px;
    }
    
    
}

@media (max-width: 900px) {

    #bgVideo{
        display: none;
    }

    #bgImage{
    display: block;
    }

    .Mint-container{
        padding-bottom: 200px;
    }

    .mint-section {
        width: 100%;
    }

    .box-one {
        display: none;
    }

    .bubble-container {
        width: 310px;
        height: 314px;
    }


    .bubble-container .bubble-container-wrapper{
        padding: 56px 66px 0 70px;
    }    
    
    .bubble-container-wrapper h3{
        font-size: 23px;
        line-height: 24px;
    }

    .bubble-container .bubble-container-wrapper .amount-box{
        width: 55px;
        height: 55px;
        margin: 0 10px;
    }

    .bubble-container .bubble-container-wrapper .bubble-two-container{
        width: 45px;
        height: 48px;
    }

    .bubble-container-wrapper-error, .bubble-container-wrapper-success{
        height: 305px;
    }

    .bubble-two-container svg{
        height: 35px;
        width: 35px;
    }

    .bubble-two-container svg:hover{
        height: 40px;
        width: 40px;
    }

}


@media (max-width: 540px) {
    .Mint {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/mint_background_500.png");
    }
    .Mint-container{
        padding-bottom: 250px;
    }
    .mint-section {
        width: 100%;
        margin-top: 25px;
    }
    .box-two {
        max-width: 312px;
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/text_container_mint_mobile.png");
        padding: 15px 15px 15px 15px;
    }

    .box-two .box-two-info-container {
        top: 40px;
    }

    .box-two .box-two-info-container .mint-description{
        padding: 0 10px;
    }

    .mint-info {
        flex-direction: column;
    }

    .box-two-contract-info:nth-child(1){
        border-right: 0;
        border-bottom: 1px solid #FCEBBC;
    }
    .box-two-contract-info{
        padding: 16px 0;
     }

     /*.mint-button-container{
        bottom: 0;
    }*/

    .mint-button-container button{
        font-size: 20px;
    }
    
    .mint-button-container button:hover{
        font-size: 21px;
    }
    
    .mint-button-container a.mint-button-text{
        letter-spacing: 0.08em;
    }
    
    .mint-button-container img{
        width: 240px;
    }

    .mint-info{
        width: 50%;
    }


    .box-two-contract-info:nth-child(1){
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .box-two-contract-info:nth-child(2){
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bubble-container-wrapper-error{
        height: 455px;
    }

    .bubble-container-wrapper-success {
        height: 305px;
        padding: 0 40px 0 40px;
    }
    
}


@media (max-width: 360px) {
    .Mint {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/mint_background_360.png");
    }
}