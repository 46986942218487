.Terms{
    position: absolute;
    top:0;
    background-color: #F9EDC5;
    display: flex;
    justify-content: center;
    padding: 100px 0 50px 0;
    width: 100%;
    min-height: calc(100vh - 150px);
}

.terms-container{
    width: 75%;
}

.terms-container h2{
    text-align: center;
    margin-bottom: 30px;
}

.terms-container div{
    padding: 20px 0;
}

.terms-container div h3{
    margin-bottom: 15px;
}

.terms-container div p{
    padding: 5px;
}

.policy{
    display: flex;
    justify-content: center;
}

.policy-container{
    width: 95%;
}

.policy-container p{
    list-style-type: circle;
}



@media (max-width: 900px) {

    .terms-container{
        width: 90%;
    }

    .policy-container{
        width: 85%;
    }
}