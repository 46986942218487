#koi01 {
    position: absolute;
    top: 7vh;
    right: 3vh;
    width: 215px;
    height: 353px;
    z-index: 4;
}

#koi02 {
   max-width: 610px;
   max-height: 931px;
   z-index: 4;
}

#koi03 {
    position: absolute;
    bottom: 0;
    right: 18vh;
    width: 890px;
    object-fit: contain;
    z-index: -1;
    overflow: hidden;
}

#koi03 img{
    position: relative;
    top: 10vh;
}



.About{ 
    position: absolute;
    top: 0;  
    width: 100%;
    height: 100vh;
    color: #000;
    background-color: #F9EDC5;
    z-index: 1;
}
/*
.about-container{
    width: 100%;
    position: relative;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 60px;
    padding-bottom: 270px;
}*/

.first-section{
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 140vh;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/about_background.png");
    background-repeat:no-repeat;
    background-position: top;
    background-size: cover;
}

.first-section-container{
    position: relative;
    width: 100%;
    top: 23vh;
    z-index: 10;
}

.team-presentation{
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 98px;
}

.team-presentation-container {
    display: flex;
    justify-content: center;
}

.team-card{
    height: 473.15px;
    width: 394.22px;
    border-radius: 20px;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/team_card.png");
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    box-shadow: 0px 37px 85px rgba(52, 12, 12, 0.25);
    margin: 0 23px;
}

.team-card-overlay{
    border-radius: 20px;
    background-color: rgb(54, 27, 7, .52);
    width: 100%;
    height: inherit;
    position: absolute;
}

.team-bubble {
    position: absolute;
    top: -80px;
    width: 216px;
    height: 226px;
    border-radius: 50%;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/team_bubble.png");
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
}

.team-bubble div {
    width: 65%;
    height: 135px;
    background-color: rgba(255, 255, 255, 0.45);
    border-radius: 50%;
    background-repeat:no-repeat;
    background-position: center;
    background-size: cover;
}

#neo {
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/neo_pfp.png");
}

#atomik {
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/atomik_pfp.png");
}

#ben {
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/ben_pfp.png");
}

.team-info{
    position: relative;
    top: 150px;
    color: #fff;
    padding: 0 35px;
}

.team-info p:nth-of-type(1){
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 8px;
    text-align: center;
}
.team-info p:nth-of-type(2){
    font-weight: 700;
    margin-bottom: 8px;
    text-align: center;
}
.team-info p:nth-of-type(3){
    text-align: left;
    text-align: center;
}

.second-section {
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 12;
    position: relative;
    top: -90vh;
    max-height: 1592px;
    height: 170vh;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/about_background02.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.second-section-wrapper{
    width: 100%;
  max-width: 1400px;
  position: relative;
  top: 530px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-section-wrapper div{
    position: relative;
    top: -30px;
    margin-left: 50px;
}

.second-section-wrapper div p{
    margin-top: 24px;
    font-weight: 500;
}

.third-section{
    /*padding-bottom: 80px;*/
    position: relative;
    top: -115vh;
    width: 100%;
    height: 80vh;
    z-index: 4;
    display: flex;
    justify-content: center;
    background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section4_background.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom right;
    mix-blend-mode: multiply;
    background-color: #F9EDC5;
    padding-bottom: 10vh;
}

.third-section-container{   
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: relative;
    top: 35vh;
    z-index:10;
}

.third-section-wrapper{
    display: flex;
    align-items: center;
   justify-content: flex-start;
    width: 100%;
    overflow: hidden;
}

.third-section-box-one{
    padding-left: 10%;
    width: 40%;
    color: #000000;
    filter: drop-shadow(0px 37px 85px rgba(52, 12, 12, 0.25));
}


.third-section-box-one h2{
    margin-bottom: 24px;
}

.third-section-box-one p{
    font-weight: 500;
}


.third-section-box-two{
    width: 60%;
    min-width: 700px;
    overflow-x: hidden;
}



@media (min-width: 900px) {

    @media (max-height: 900px) {

        .third-section-container{   
            top: 25vh;
        }
    }
    
    @media (max-height: 760px) {

        .third-section{
            height: 85vh;
        }
    }
    
    
}

@media (max-width: 2050px) {

    .second-section {
        max-height: 1192px;
    }

    .second-section-wrapper{
        top: 350px;
    }
}

@media (max-width: 1500px) {

    #koi03 {
        right: 0;
        width: 890px;
    }

    .first-section{
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/about_background_1440.png");
    }

    .second-section {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/about_background02_1440.png");
        top: -13vh;
        height: 110vh;
    }

    .second-section-wrapper{
      top: -10vh;
    }

    
    .third-section{
        /*padding-bottom: 80px;*/
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section4_background_1440.png");
        position: relative;
        top: -33vh;
        width: 100%;
        height: 85vh;
        padding-bottom: 10vh;
    }

   
    .team-card{
        height: 473.15px;
        width: 295px;
        margin: 0 17px;
    }

    .second-section-wrapper div{
        position: relative;
        top: -30px;
        margin-left: 0;
    }    
    
    
}

@media (max-width: 900px) {

    #koi03 {
        display: none;
    }
    
    .first-section{
        height: 150vh;
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/about_background_768.png");
    }
    
    .team-presentation{
        overflow-x: scroll;
        overflow-y: visible;
        justify-content: flex-start;
    }

    .team-presentation-container{
        justify-content: flex-start;
    }
   
    .team-card{
        height: 473px;
        width: 560px;
        margin: 0 25px;
    }

    .second-section {
       /* top: -15vh;*/
        height: 140vh;
    }

    .second-section-container{
        display: flex;
        justify-content: center;
    }

    .second-section-wrapper{
        width: 90%;
        top: 110px;
        flex-direction: column-reverse;
        align-items: center;
        margin: 0;
      }

    .second-section-wrapper div{
        position: relative;
        top: 0;
        margin-left: 0;
    }

    .second-section-wrapper div h2{
       text-align: center;
    }

    #koi02 {
        max-width: 405px;
        max-height: 665px;
        z-index: 4;
     }


     .third-section{
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section4_background_768.png");
        top: -40vh;
        z-index: 2;
        height: 85vh;
        padding-bottom: 80vh;
     }

     .third-section-container{   
        top: 50vh;
    }

     .third-section-wrapper{
        flex-direction: column;
       justify-content: center;
    }
    .third-section-box-one{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        width: 85%;
        margin-bottom: 52px;
    }

    .third-section-box-one h2{
        margin-bottom: 16px;
    }
    .third-section-box-one p{
        text-align: center;
    }

    .third-section-box-two{
        width: 100%;
    }

    @media (min-height: 600px) {
        .first-section{
            height: 130vh;
        }

        .second-section {
             height: 130vh;
         }

         .second-section-wrapper{
            top: 20vh;
          }
    

        .third-section{
            padding-bottom: 60vh;
         }

         .third-section-container{   
            top: 45vh;
        }
    }


    @media (min-height: 800px) {
        .first-section{
            height: 107vh;
        }

        .first-section-container{
            top: 16vh;
        }

        .first-section-wrapper h2{
            margin-bottom: 30px;
        }

        .second-section {
             height: 110vh;
         }

        .third-section{
            padding-bottom: 30vh;
         }

         .third-section-container{   
            top: 40vh;
        }
    }


    @media (min-height: 1000px) {
        .first-section{
            height: 95vh;
        }

        .first-section-wrapper h2{
            margin-bottom: 30px;
        }

        .second-section {
             height: 90vh;
         }
         
         .second-section-wrapper{
            top: 10vh;
          }


        .third-section{
            padding-bottom: 10vh;
         }

         .third-section-container{   
            top: 35vh;
        }
    }
    
}

@media (max-width: 768px) {
    
    .first-section-wrapper h2{
        font-size: 36px;
        line-height: 40px;
    }

    #koi01 {
       display: none;
    }

   /* .second-section {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/about_background02_768.png");
    }*/
    
}

@media (max-width: 500px) {

    .first-section{
        height: 100vh;
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/about_background_360.png");
    }
   
    .team-card{
        height: 485px;
        width: 262px;
        margin: 0 16px;
    }

    .second-section {
        height: 63vh;
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/about_background02_360.png");
    }

    .second-section-wrapper{
         top: 12vh;
    }

    .second-section-wrapper div h2{
        text-align: left;
        font-size: 36px;
        line-height: 40px;
     }

    #koi02 {
        max-width: 235px;
        max-height: 386px;
     }

    .third-section{
        top: -22vh;
        height: 85vh;
        padding-bottom: 3vh;
     }

     .third-section-container{   
        top: 25vh;
    }

    .third-section-box-one{
        align-items: flex-start;
    }
 
    .third-section-box-one h2{
        font-size: 36px;
        line-height: 40px;
    }
    .third-section-box-one p{
        text-align: left;
    }

    .third-section footer{
        bottom: 0;
    }

    @media (max-height: 1000px) {

        
        .first-section{
            height: 125vh;
        }

        .second-section {
            height: 85vh;
        }

        .second-section-wrapper{
            top: 16vh;
       }

        .third-section{
            padding-bottom: 22vh;
         }

         .third-section-container{   
            top: 27vh;
        }

    }  
    
    @media (max-height: 800px) { 
        .first-section{
            height: 138vh;
        }

        .second-section {
            height: 100vh;
        }

        .second-section-wrapper{
            top: 19vh;
       }

        .third-section{
            padding-bottom: 48vh;
         }

         .third-section-container{   
            top: 35vh;
        }
    }

      
    @media (max-height: 650px) { 
        .first-section{
            height: 155vh;
        }

        .second-section {
            height: 125vh;
        }

        .second-section-wrapper{
            top: 22vh;
       }

        .third-section{
            padding-bottom: 70vh;
         }

         .third-section-container{   
            top: 44vh;
        }
    }


}

@media (max-width: 360px) {
    .second-section {
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/about_background02_360.png");
    }

    .third-section{
        background-image: url("https://s3.amazonaws.com/fukushimafish.io/website_assets/home_section4_background_360.png");
    }



}



